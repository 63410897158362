import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import vuetify from "./vuetify";
import { i18n } from "./i18n";
import DevDiv from "./components/common/DevDiv.vue";
import { formatCurrency, formatNumber } from "./utils/commonFunctions";
import p from "../package.json"

let devMode = ["192.168.66.223", "192.168.66.183", "192.168.0.23", "192.168.67.18"].includes(window.location.hostname);
Vue.config.productionTip = false;

console.log("Version: v" + p.version);

Vue.mixin({
  data: () => ({ devMode: devMode, formatCurrency, formatNumber }),
  computed: {
    s() {
      return this.$store.state;
    }
  }
});

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("dev", DevDiv);

window.i18n = i18n;
window.vue = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
