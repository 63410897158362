import Vue from "vue";
import Vuex from "vuex";
import { LANGUAGES_KEYS } from "@/utils/constants";
Vue.use(Vuex);

function countries() {
  let x = {};
  LANGUAGES_KEYS.forEach(key => (x[key] = []));
  return x;
}

export default new Vuex.Store({
  state: {
    isMobile: false, // Mobile size breakpoint @ 960px
    countries: countries(),
    messagesCount: null,
    serverDate: null,
    currentBreadCrumb: null,
    generatingPDF: false, // flag to indicate PDF generation in progress for everyone

    // =========================== Cache =====================================================================
    contracts: [], // list of contracts
    contractsFilters: null,  // contracts list filters state
    contract: null, // active contract
    payments: [], // list of payments
    paymentsFilters: null, // payments list filters state
    clients: [], // list of clients in sadmin view
    clientsFilter: null, // sadmin view client list filters state
    // =========================== Global component accessors ================================================
    rpc: null, // RPCHandler.vue - RPC method with minimal logic
    login: null, // LoginHandler.vue - Login handler only
    snackbar: null, // ErrorSnackbar.vue - quick global access to error snackbar
    // =========================== User Control ==============================================================
    loggedIn: false,
    user: { userName: null, displayName: null, readonly: false, specifyWho: false, userCode: null, forceUpdate: false, needAml: false, warning: false, needTCConfirm: false, agreedToDocManagement: false, natural: 1, admin: false, sadmin: false },
    representative: { name: null, code: null }, // client (juridical) representative information
    selectedRole: null, // user role
    limitedAccess: null, // allow access Contracts
  },
  getters: {},
  mutations: {
    resetCached(s) {
      s.contracts.length = 0;
      s.contractsFilters = null;
      s.contract = null;
      s.payments.length = 0;
      s.paymentsFilters = null;
      s.client = null;
      s.clients.length = 0;
      s.clientsFilter = null;
    }
  },
  actions: {},
  modules: {}
});
