"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  badge: "Ženklelis",
  dataIterator: { noResultsText: "Nėra duomenų", loadingText: "Duomenys kraunami..." },
  dataTable: {
    itemsPerPageText: 'Eilutės per puslapį:',
    ariaLabel: {
      sortDescending: ': Išrikiuota mažėjimo tvarka. Suaktyvinkite, jei norite rikiavimą pašalinti.',
      sortAscending: ': Išrikiuota didėjimo tvarka. Suaktyvinkite, jei norite rikiuoti mažėjimo tvarka.',
      sortNone: ': Nerikiuota. Suaktyvinkite, jei norite rikiuoti didėjimo tvarka.',
      activateNone: "",
      activateAscending: "Surikiuoti didėjimo tvarka",
      activateDescending: "Surikiuoti mažėjimo tvarka"
    },
    sortBy: 'Rūšiuoti pagal'
  },
  dataFooter: {
    itemsPerPageText: 'Įrašai per puslapį:',
    itemsPerPageAll: 'Visi',
    nextPage: 'Kitas puslapis',
    prevPage: 'Ankstesnis puslapis',
    firstPage: 'Pirmas puslapis',
    lastPage: 'Paskutinis puslapis',
    pageText: '{0}-{1} iš {2}'
  },
  datePicker: {
    itemsSelected: "{0} pasirinkta",
    prevMonthAriaLabel: "Praeitas mėnesis",
    nextMonthAriaLabel: "Sekantis mėnesis",
    prevYearAriaLabel: "Praeiti metai",
    nextYearAriaLabel: "Sekantys metai"
  },
  noDataText: "Nėra duomenų",
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  fileInput: {
    counter: "Kiekis"
  },
  close: "Uždaryti"
};
exports.default = _default;
