<template>
  <div v-if="devMode" :class="{ dev: !silent }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "dev-div",
  props: { silent: Boolean },
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style scoped lang="scss"></style>
