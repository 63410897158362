<template>
  <v-app id="app" style="width: 100%">
    <ErrorSnackbar />
    <RPCHandler />
    <LoginHandler />
    <SessionHandler v-if="s.loggedIn" />
    <template v-if="s.rpc">
      <div class="mainWrapper">
        <Header />
        <router-view :class="s.isMobile ? 'pt10 pb40' : 'pt20 pb100'"></router-view>
      </div>
      <Footer />
    </template>
    <DevToolbar v-if="devMode" />
    <!-- <dev style="position: fixed; bottom: 0; right: 0">{{ s.user.admin }}/{{ s.user.sadmin }}</dev> -->
  </v-app>
</template>
<script>
import { ROLE_ADMIN, ROLE_CLIENT, ROLE_SADMIN, ROUTE_CONTRACTS, LANG_KEY } from "./utils/constants";
import { loadLanguageAsync } from "./i18n";
export default {
  name: "App",
  components: {
    Footer: () => import(/* webpackChunkName: "com" */ "@/components/Footer.vue"),
    Header: () => import(/* webpackChunkName: "com" */ "./components/header/Header.vue"),
    DevToolbar: () => import(/* webpackChunkName: "com" */ "./components/DevToolbar.vue"),
    RPCHandler: () => import(/* webpackChunkName: "han" */ "./handlers/RPCHandler.vue"),
    LoginHandler: () => import(/* webpackChunkName: "han" */ "./handlers/LoginHandler.vue"),
    SessionHandler: () => import(/* webpackChunkName: "han" */ "./handlers/SessionHandler.vue"),
    ErrorSnackbar: () => import(/* webpackChunkName: "com-com" */ "@/components/common/ErrorSnackbar.vue")
  },
  data: () => ({}),
  watch: {
    // nav guard on page load:
    "s.loggedIn": function () {
      if (this.$route.meta?.hideFor && this.$route.meta?.hideFor.includes(this.s.user.admin ? ROLE_ADMIN : this.s.user.sadmin ? ROLE_SADMIN : ROLE_CLIENT))
        this.$router.replace({ name: ROUTE_CONTRACTS });
    }
  },
  mounted() {
    loadLanguageAsync(localStorage.getItem(LANG_KEY) || ((window.vue && window.vue.$i18n && window.vue.$i18n.locale) ? window.vue.$i18n.locale : "lt"));
    if (this.devMode && localStorage.getItem("dark")) this.$vuetify.theme.dark = true;
    const resizeObserver = new ResizeObserver(el => {
      this.s.isMobile = el[0].borderBoxSize[0].inlineSize < 960;
    });
    resizeObserver.observe(document.getElementById("app"));
  },
  methods: {},
  computed: {}
};
</script>
<style lang="scss">
@import "./scss/cssOverrides.scss";
@import "./scss/spacings.scss";
@import "./scss/global.scss";
@import "./scss/colors.scss";
</style>
