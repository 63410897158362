import Vue from "vue";
import VueRouter from "vue-router";
import {
  ROLE_ADMIN,
  ROLE_CLIENT,
  ROLE_SADMIN,
  ROUTE_CLAIM,
  ROUTE_CLIENT,
  ROUTE_CLIENTINFO,
  ROUTE_CLIENTS,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTS,
  ROUTE_FAQ,
  ROUTE_LOGIN,
  ROUTE_MESSAGE,
  ROUTE_MESSAGES,
  ROUTE_PAYMENT,
  ROUTE_PAYMENTS,
  ROUTE_REQUESTS,
  ROUTE_ROLES,
  ROUTE_SYSPARAMS,
  ROUTE_USER_UPDATE,
  ROUTE_TRANSLATIONS,
  ROUTE_USERS,
  ROUTE_REPORTS,
  ROUTE_KYC,
  ROUTE_SENDINGWAYS
} from "./utils/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/contracts",
    name: ROUTE_CONTRACTS,
    component: () => import(/* webpackChunkName: "views" */ "./views/ContractsView.vue")
  },
  {
    path: "/contract",
    name: ROUTE_CONTRACT,
    component: () => import(/* webpackChunkName: "views" */ "./views/ContractView.vue"),
    meta: {
      parent: ROUTE_CONTRACTS,
      breadCrumb(route) {
        return [
          { text: window.vue.$t("route.bc.contracts"), to: { name: ROUTE_CONTRACTS }, exact: true },
          { text: window.vue.$t("route.bc.oneContract", { id: route.query.cno }) }
        ];
      }
    }
  },
  {
    path: "/beneficiary",
    name: "beneficiary",
    component: () => import(/* webpackChunkName: "views" */ "./views/InsureeView.vue"),
    meta: { parent: ROUTE_CONTRACTS }
  },
  {
    path: "/login",
    name: ROUTE_LOGIN,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/LoginView.vue")
  },
  {
    path: "/claimRegistration",
    name: ROUTE_CLAIM,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/ClaimRegistrationView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.claimRegistration") }];
      }
    }
  },
  {
    path: "/clients",
    name: ROUTE_CLIENTS,
    component: () => import(/* webpackChunkName: "views" */ "./views/ClientsView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN, ROLE_CLIENT],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.clients") }];
      }
    }
  },
  {
    path: "/client",
    name: ROUTE_CLIENT,
    component: () => import(/* webpackChunkName: "views" */ "./views/ClientView.vue"),
    meta: {
      parent: ROUTE_CLIENTS,
      hideFor: [ROLE_ADMIN, ROLE_CLIENT],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.clients"), to: { name: ROUTE_CLIENTS }, exact: true }, { text: window.vue.$t("route.bc.client", { name: "" }) }];
      }
    }
  },
  {
    path: "/clientInfo",
    name: ROUTE_CLIENTINFO,
    component: () => import(/* webpackChunkName: "views" */ "./views/BaseInfo.vue"),
    meta: {
      hideFor: [ROLE_ADMIN, ROLE_SADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.clientInfo"), to: { name: ROUTE_CLIENTINFO }, exact: true }];
      }
    }
  },
  {
    path: "/faq",
    name: ROUTE_FAQ,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/FAQView.vue"),
    meta: {
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.faq") }];
      }
    }
  },
  {
    path: "/messages",
    name: ROUTE_MESSAGES,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/MessagesView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.messages") }];
      }
    }
  },
  {
    path: "/message",
    name: ROUTE_MESSAGE,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/MessageView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.messages"), to: { name: ROUTE_MESSAGES }, exact: true }, { text: window.vue.$t("route.bc.message") }];
      }
    }
  },
  {
    path: "/payments",
    name: ROUTE_PAYMENTS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/PaymentsView.vue"),
    meta: {
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.payments") }];
      }
    }
  },
  {
    path: "/payment",
    name: ROUTE_PAYMENT,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/PaymentView.vue"),
    meta: {
      parent: ROUTE_PAYMENTS,
      breadCrumb(route) {
        return [
          { text: window.vue.$t("route.bc.payments"), to: { name: ROUTE_PAYMENTS }, exact: true },
          { text: window.vue.$t("route.bc.payment", { id: route.query.cno }) }
        ];
      }
    }
  },
  {
    path: "/requests",
    name: ROUTE_REQUESTS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/RequestsView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc.requests") }];
      }
    }
  },
  {
    path: "/roles",
    name: ROUTE_ROLES,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/ChangeRole.vue")
  },
  {
    path: "/user",
    name: ROUTE_USER_UPDATE,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/UpdateInfo.vue")
  },
  {
    path: "/sysparams",
    name: ROUTE_SYSPARAMS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/SysParamsView.vue"),
    meta: {
      hideFor: [ROLE_CLIENT, ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_SYSPARAMS) }];
      }
    }
  },
  {
    path: "/systrans",
    name: ROUTE_TRANSLATIONS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/TranslationsView.vue"),
    meta: {
      hideFor: [ROLE_CLIENT, ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_TRANSLATIONS) }];
      }
    }
  },
  {
    path: "/users",
    name: ROUTE_USERS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/UsersView.vue"),
    meta: {
      hideFor: [ROLE_CLIENT, ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_USERS) }];
      }
    }
  },
  {
    path: "/reports",
    name: ROUTE_REPORTS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/ReportsView.vue"),
    meta: {
      hideFor: [ROLE_CLIENT, ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_REPORTS) }];
      }
    }
  },
  {
    path: "/kyc",
    name: ROUTE_KYC,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/KYCView.vue"),
    meta: {
      hideFor: [ROLE_ADMIN],
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_KYC) }];
      }
    }
  },
  {
    path: "/sendingInfo",
    name: ROUTE_SENDINGWAYS,
    component: () => import(/* webpackChunkName: "views" */ "../src/views/SendingInfoView.vue"),
    meta: {
      breadCrumb() {
        return [{ text: window.vue.$t("route.bc." + ROUTE_SENDINGWAYS) }];
      }
    }
  },
  {
    path: "/*",
    redirect: "/contracts"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (window.vue) {
    window.vue.s.currentBreadCrumb = null;
    if (!window.vue.s.loggedIn && !window.vue.s.user.specifyWho && to.name !== ROUTE_LOGIN) {
      return next({ name: ROUTE_LOGIN });
    }
    if (window.vue.s.user.specifyWho && to.name !== ROUTE_ROLES) return next({ name: ROUTE_ROLES });
    if (
      window.vue.s.loggedIn &&
      window.vue.s.user.forceUpdate &&
      window.vue.s.selectedRole !== ROLE_ADMIN &&
      window.vue.s.selectedRole !== ROLE_SADMIN &&
      to.name !== ROUTE_USER_UPDATE &&
      to.name !== ROUTE_CONTRACTS &&
      to.name !== ROUTE_CLIENTINFO &&
      to.name !== ROUTE_CONTRACT &&
      to.name !== ROUTE_MESSAGES &&
      to.name !== ROUTE_MESSAGE &&
      to.name !== ROUTE_KYC
    )
      return next({ name: ROUTE_USER_UPDATE });
    if (window.vue.s.loggedIn && to.name === ROUTE_LOGIN) return next({ name: ROUTE_CONTRACTS });
    if (to.meta && to.meta.hideFor && to.meta.hideFor.includes(window.vue.s.user.admin ? ROLE_ADMIN : window.vue.s.user.sadmin ? ROLE_SADMIN : ROLE_CLIENT))
      return next({ name: ROUTE_CONTRACTS });
  }
  next();
});

router.afterEach(() => window.scrollTo(0, 0));

export default router;
