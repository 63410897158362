"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = void 0;
var _default = {
    badge: "Значок",
    dataIterator: { noResultsText: "Нет данных", loadingText: "Данные зaгpyжaютcяbf..." },
    dataTable: {
        itemsPerPageText: 'Строк на странице:',
        ariaLabel: {
            sortDescending: ': Выстроились в порядке убывания. Активируйте, чтобы убрать сортировку.',
            sortAscending: ': Выстроились в порядке возрастания. Активируйте для сортировки по убыванию.',
            sortNone: ': Неупорядоченный. Активируйте, чтобы отсортировать по возрастанию.',
            activateNone: "",
            activateAscending: "Сортировать по возрастанию",
            activateDescending: "Сортировать по убыванию"
        },
        sortBy: 'Сортировать по'
    },
    dataFooter: {
        itemsPerPageText: 'Сообщений на странице:',
        itemsPerPageAll: 'Каждый',
        nextPage: 'Следущая страница',
        prevPage: 'Предыдущая страница',
        firstPage: 'Первая страница',
        lastPage: 'Последняя страница',
        pageText: '{0}-{1} из {2}'
    },
    datePicker: {
        itemsSelected: "{0} выбран",
        prevMonthAriaLabel: "Прошлый месяц",
        nextMonthAriaLabel: "B следующем месяце",
        prevYearAriaLabel: "B прошлом году",
        nextYearAriaLabel: "B следующем году"
    },
    noDataText: "Нет данных",
    timePicker: {
        am: "AM",
        pm: "PM"
    },
    fileInput: {
        counter: "Количество"
    },
    close: "Закрывать"
};
exports.default = _default;
