import Vue from "vue";
import VueI18n from "vue-i18n";
import { ERROR_AUTH, ERROR_CONN, ERROR_UNKNOWN, ERROR_AML, ERROR_EXCHANGE, LANG_KEY } from "@/utils/constants";

Vue.use(VueI18n);

// // ----------------------- working with local .json
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
//   const messages = {};
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'lt',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'lt',
//   messages: loadLocaleMessages(),
//   pluralizationRules: {
//     /**
//      * Text "linksniavimas" based on number. i18n JSON must contain "|" 3-4 separated texts:
//      * 1. Tens and teens, and Zero if less than 4 options in translation
//      * 2. Numbers that end with 1
//      * 3. Other numbers
//      * 4. (Optional) Special text for 0
//      * @param {Number} num number to base the text selection on. Given by the input to $tc: `$tc('path.to.rule', num)`
//      * @param {Number} choicesLength an overall amount of available choices
//      * @returns {Number} a final choice index to select plural word by
//      */
//     'lt': function (num, choicesLength) {
//       // this === VueI18n instance, so the locale property also exists here
//       if (num === 0) return choicesLength >= 4 ? 3 : 0;
//       const tens = num % 10 === 0;
//       const teens = num > 10 && num < 20;
//       const endsWithOne = num % 10 === 1;
//       if (choicesLength < 3 || teens || tens) return 0;
//       else if (endsWithOne) return 1;
//       else return 2;
//     }
//   }
// });

// -------- working for translations from backend ----------------


export const i18n = new VueI18n({
  locale: localStorage.getItem(LANG_KEY) || 'lt',
  // fallbackLocale: 'lt',
});


const loadedLanguages = [];
var prevLang = null;
var langCommons = {};

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function getPrevLangCommons() {
  if (loadedLanguages.includes(prevLang)) return langCommons[prevLang];
}

export function loadLanguageAsync(lang) {
  prevLang = i18n.locale;
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  return fetch(endpoint("/translations/json", { locale: lang }), genGetParams())
    .then(response => parseResponse(response))
    .then(response => {
      loadedLanguages.push(lang);
      let langData = JSON.parse(response.json);
      i18n.setLocaleMessage(lang, langData);
      setI18nLanguage(lang);
    });
}

function endpoint(path, query = "") {
  let x = makeURL(path);
  x.search = new URLSearchParams(query);
  return x;
}
function genGetParams() {
  return { method: "GET", mode: "cors", cache: "no-cache", credentials: "include" };
}
function makeURL(path) {
  return new URL((process.env.VUE_APP_BACKEND_HOST.startsWith("http") ? "" : window.location.origin) + process.env.VUE_APP_BACKEND_HOST + path);
}
function parseResponse(response) {
  if (response.status >= 300) {
    console.error("Response 300+ error:", response);
    this.s.snackbar.showError(ERROR_CONN);
    return Promise.reject(ERROR_CONN);
  }
  return response.json().then(respJSON => {
    if (respJSON && respJSON.error === ERROR_AUTH) {
      if (this.s.loggedIn) this.s.snackbar.showError(ERROR_AUTH);
      this.s.login.logout();
    }
    if (respJSON && (respJSON.error === ERROR_UNKNOWN || respJSON.error === ERROR_AML || respJSON.error === ERROR_EXCHANGE))
      this.s.snackbar.showError(respJSON.error)
    return respJSON;
  }).catch(err => {
    console.error("Could not parse response as JSON", err);
    return Promise.reject(err);
  });
}