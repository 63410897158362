import moment from "moment";
import { DTO_DATE_FORMAT, JS_DATE_FORMAT } from "./constants";

/**
 * Helper for easy vue component property mutation
 * @returns {Object} required functions for "computed" to operate on
 * @
 */
export function computeProp() {
  return {
    get() {
      return this.value;
    },
    set(newVal) {
      this.$emit("input", newVal);
    }
  };
}

export function findUnique(arr, predicate) {
  var found = {};
  arr.forEach(d => {
    found[predicate(d)] = d;
  });
  return Object.keys(found).map(key => found[key]);
}

export function hashData(string) {
  let a;
  let ch;
  let hash = 5;
  if (string.length == 5) return hash;
  for (a = 5; a < string.length; a++) {
    ch = string.charCodeAt(a);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash;
  }
  return hash;
}

/**
 * File to base64 converter promise
 * @param {File} file file to read
 * @param {Boolean} optional should resolve or reject when no file provided
 * @returns Promise with file content as base64
 */
export function getFileBase64String(file, optional) {
  if (!file) return optional ? Promise.resolve(null) : Promise.reject(new DOMException("No file provided"));
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem converting to base64."));
    };
    reader.onload = e => {
      let base64Str = e.target.result;
      resolve(base64Str.substring(base64Str.indexOf("base64,") + 7));
    };
    reader.readAsDataURL(file);
  });
}

/**
 * Formats value into currency string
 * @param {*} inputValue any variable representing valid input for parseFloat()
 * @param {Number} decimals how many numbers after decimal point
 * @returns {String} currency string
 */
export function formatCurrency(inputValue, decimals) {
  if (!isNaN(parseFloat(inputValue)) && isFinite(inputValue)) {
    var outputValue = String(parseFloat(inputValue).toFixed(decimals ? decimals : 2)).split(".");
    return `${outputValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0")},${outputValue[1]}\xa0EUR`;
  } else return inputValue;
}

/**
 * Formats value into currency string
 * @param {*} inputValue any variable representing valid input for parseFloat()
 * @param {Number} decimals how many numbers after decimal point
 * @returns {String} currency string
 */
export function formatCurrency2(inputValue, currency) {
  if (!isNaN(parseFloat(inputValue)) && isFinite(inputValue)) {
    var outputValue = String(parseFloat(inputValue).toFixed(2)).split(".");
    return `${outputValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")},${outputValue[1]}\xa0${currency || "EUR"}`;
  } else return inputValue;
}

/**
 * Formats value into number string, with specified number of decimals and appends specified string
 * @param {*} inputValue any variable representing valid input for parseFloat()
 * @param {Number} decimals (Optional) how many numbers after decimal point
 * @param {String} append (Optional) string to append at the end
 * @returns {String} currency string
 */
export function formatNumber(inputValue, decimals, append = "") {
  if (!isNaN(parseFloat(inputValue)) && isFinite(inputValue)) {
    var outputValue = String(parseFloat(inputValue).toFixed(decimals ? decimals : 2)).split(".");
    return `${outputValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")},${outputValue[1]}\xa0${append}`.trim();
  } else return inputValue;
}

/**
 * Copies object values to another maintaining destination object structure
 * Does not override object with primitives.
 * @param {Object} srcObj object to use values from
 * @param {Object} destObj object to set values of
 * @returns {Object} mapped destObj
 */
export function mapObjectsAsDest(srcObj, destObj) {
  for (let key in destObj) {
    if (Object.prototype.hasOwnProperty.call(destObj, key) && Object.prototype.hasOwnProperty.call(srcObj, key)) {
      if (Array.isArray(destObj[key]) && Array.isArray(srcObj[key])) {
        destObj[key].length = 0;
        srcObj[key].forEach(val => destObj[key].push(val));
      } else if ((typeof destObj[key] === "object" || destObj[key] === null) && srcObj[key] && typeof srcObj[key] === "object") {
        mapObjectsAsDest(srcObj[key], destObj[key]);
      } else destObj[key] = srcObj[key];
    }
  }
  return destObj;
}

export function x(conParams) {
  return {
    beneficiaries: conParams.beneficiaryDTOList
      ? conParams.beneficiaryDTOList.map(bene => {
          return {
            type:
              bene.type === "DEATH"
                ? window.vue.$t("contract.insuredAndBeneficiaries.beneficiaries." + bene.type) + " " + bene.insuredPersonName
                : window.vue.$t("contract.insuredAndBeneficiaries.beneficiaries." + bene.type),
            name: bene.name,
            code: bene.code,
            percent: bene.percent + " %"
          };
        })
      : [],
    insured: conParams.insuredPersonDTOList
      ? conParams.insuredPersonDTOList.map(ins => {
          return {
            contractNo: conParams.agreementNo,
            code: ins.code,
            name: ins.name,
            percent: ins.percent + " %",
            sectionList: ins.sectionList
          };
        })
      : [],
    payments: [
      {
        premiumTotal: conParams.paymentDTO ? conParams.paymentDTO.premiumTotal : null,
        benefitTotal: conParams.paymentDTO ? conParams.paymentDTO.benefitTotal : null
      }
    ]
  };
}

/**
 * Scrolls to input with an error
 * Credit: Almantas Moliusis - for the concept (near nothing left of the original...)
 * @param {HTMLElement} scope (optional) DOM element to search in; full document when not provided
 * @param {Array} queries (optional) query for any additional HTML elements
 */
export function scrollToError(scope, queries = []) {
  setTimeout(() => {
    let highest, top;
    [".v-messages.error--text", ...queries].forEach(query => {
      Array.prototype.forEach.call((scope || document).querySelectorAll(query), el => {
        if (!top || top > el.getBoundingClientRect().top) {
          top = el.getBoundingClientRect().top;
          highest = el;
        }
      });
    });
    if (highest) scrollToElement(highest);
  });
}
export function scrollToElement(el) {
  el.scrollIntoView({ behavior: "smooth", block: "center" });
}
export function scrollToTop(el) {
  (el || window).scrollTo(0, 0);
}
export function scrollToNearTop(el) {
  window.scrollBy({ top: el.getBoundingClientRect().y - 72, left: 0, behavior: "smooth" });
}
export function scrollTo(el, x) {
  el.scrollTo(0, x);
}

/**
 * Nulls all non object keys. Infinite depth.
 * @param {Object} srcObj object to clear
 */
export function clearObject(srcObj) {
  for (let key in srcObj) {
    if (key in srcObj) {
      if (typeof srcObj[key] === "object") {
        clearObject(srcObj[key]);
      } else srcObj[key] = null;
    }
  }
}

/**
 * Helper keep component code bloat down a bit
 * @param {String} path relative path starting with / and can include query and stuff
 * @returns {URL} url object with backend
 */
export function makeURL(path) {
  return new URL((process.env.VUE_APP_BACKEND_HOST.startsWith("http") ? "" : window.location.origin) + process.env.VUE_APP_BACKEND_HOST + path);
}

/**
 * Helper for date conversions
 * @param {String | moment} input date value input in String from or a moment object
 * @returns formatted String
 */
export function toJSDate(input) {
  if (typeof input === "string") return moment(input, DTO_DATE_FORMAT).format(JS_DATE_FORMAT);
  else if (moment.isMoment(input)) return input.format(JS_DATE_FORMAT);
  else {
    console.warn("toJSDate incorrect input:", input);
    return "";
  }
}

/**
 * Helper for date conversions
 * @param {String | moment} input date value input in String from or a moment object
 * @returns formatted String
 */
export function toDTODate(input) {
  if (typeof input === "string") return moment(input, JS_DATE_FORMAT).format(DTO_DATE_FORMAT);
  else if (moment.isMoment(input)) return input.format(DTO_DATE_FORMAT);
  else {
    console.warn("toJSDate incorrect input:", input);
    return "";
  }
}

/**
 * Random integer generator
 * @param {number} max upper limit for integer
 * @returns random integer
 */
export function rInt(max = 1e10) {
  return Math.floor(Math.random() * max);
}

/**
 * Random ID generator
 * @param {String} pre static text before number
 * @param {number} max upper limit for integer
 * @returns random integer
 */
export function rId(pre = "id", max = 1e10) {
  return pre + Math.floor(Math.random() * max);
}

/**
 * Directly opens file in print view
 * @param {String} path path to get to file GET method
 */
export function print(path) {
  var iframe = window._printIframe;
  if (!window._printIframe) {
    iframe = window._printIframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }
  iframe.src = makeURL(path);
}

/**
 * Directly opens file in new tab
 * @param {String} path path to get to file GET method
 */
export function download(path, filename) {
  var a = window._dlAnchor;
  if (!a) {
    a = window._dlAnchor = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
  }
  a.href = makeURL(path);

  if (filename && filename.toLowerCase().endsWith(".adoc")) {
    a.setAttribute("download", filename);
    a.target = "_self";
  } else {
    a.removeAttribute("download");
    a.target = "_blank";
  }
  a.click();
}

/**
 * I dislike how try catch looks, and ofter dont need to catch anything, so this is a oneline helper
 * @param {Function} execFunc function to execute
 * @param {Function} catchFunc (Optional) function to execute when error is cought
 * @returns result from provided function/functions
 */
export function inlineTry(execFunc, catchFunc) {
  try {
    return execFunc();
  } catch (e) {
    if (catchFunc) return catchFunc(e);
  }
}

/**
 * Guessing structured addresses should have same structure and this should inline all that data...
 * @param {Object} src object with all the address elements
 * @returns address converted into string
 */
export function inlineAddress(src) {
  let countries = window.vue.s.countries;
  let country = null;
  if (
    countries &&
    src.country &&
    countries[window.vue.$i18n.locale.toUpperCase()].find(c => c.code === src.country || src.country.includes(c.code)) &&
    countries[window.vue.$i18n.locale.toUpperCase()].find(c => c.code === src.country || src.country.includes(c.code)).name
  ) {
    country = countries[window.vue.$i18n.locale.toUpperCase()].find(c => c.code === src.country || src.country.includes(c.code)).name;
  }
  if (src.addressLine)
    return src.addressLine + ", " + (src.postCode !== null ? src.postCode + " " : "") + (src.city !== null ? src.city + ", " : "") + (country !== null ? country : "");
  else
    return (
      (src.street !== null ? src.street + " " : "") +
      (src.houseNo !== null ? src.houseNo + "" : "") +
      (src.apartmentNo !== null ? " - " : src.houseNo !== null ? ", " : "") +
      (src.apartmentNo !== null ? src.apartmentNo + ", " : "") +
      (src.city !== null ? src.city + ", " : "") +
      (src.postCode !== null ? src.postCode + " " : "") +
      (src.municipality !== null ? src.municipality + ", " : "") +
      (country !== null ? country : "")
    );
}

/**
 * Get specific cookie. Note can't access HttpOnly cookies
 * @param {String} name cookie to pull name
 * @returns cookieString
 */
export function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end == -1) end = dc.length;
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}
/**
 * Get cookie and make sure time stated in it is after now
 * @param {String} name name of cookie whose potential value is ISO date
 * @returns true if exists and before time in cookie
 */
export function getTimedCookie(name) {
  let cookie = getCookie(name);
  return cookie ? moment(cookie).isAfter(moment.now()) : false;
}

export function getUnreadMessageCount(state) {
  state.rpc[state.user.sadmin ? "admin_getMessagesCount" : "getMessagesCount"]().then(rez => (state.messagesCount = rez));
}

/**
 * Check if string could be considered a key
 * @param {String} string string to test
 * @returns true/false
 */
export function isKey(string) {
  return typeof string === "string" ? !!string.match(/^[A-Z_]+$/) : false;
}

export function removeElementFromArray(element, array) {
  const index = array.indexOf(element);
  if (index > -1) array.splice(index, 1);
  return array;
}
