import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from "vuetify";
import lt from "./locales/lt";
import en from "./locales/en";
import ru from "./locales/ru";
import { LANG_KEY } from "@/utils/constants";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { lt, en, ru },
    current: localStorage.getItem(LANG_KEY) || 'lt'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0054A4",
        secondary: "#002D64",
        accent: "#F9F9FA"
      },
      dark: {
        accent: "#111111"
      }
    }
  }
});
