"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = void 0;
var _default = {
    badge: "Badge",
    dataIterator: { noResultsText: "No data", loadingText: "Loading..." },
    dataTable: {
        itemsPerPageText: 'Items per page:',
        ariaLabel: {
            sortDescending: ': Lined up in descending order. Activate to remove sorting.',
            sortAscending: ': Lined up in ascending order. Activate to sort in descending order.',
            sortNone: ': Unordered. Activate to sort in ascending order.',
            activateNone: "",
            activateAscending: "Sort in ascending order",
            activateDescending: "Sort in descending order"
        },
        sortBy: 'Sort by'
    },
    dataFooter: {
        itemsPerPageText: 'Items per page:',
        itemsPerPageAll: 'All',
        nextPage: 'Next page',
        prevPage: 'Previous page',
        firstPage: 'First page',
        lastPage: 'Last page',
        pageText: '{0}-{1} of {2}'
    },
    datePicker: {
        itemsSelected: "{0} selected",
        prevMonthAriaLabel: "Last month",
        nextMonthAriaLabel: "Next month",
        prevYearAriaLabel: "Last year",
        nextYearAriaLabel: "Next year"
    },
    noDataText: "No data",
    timePicker: {
        am: "AM",
        pm: "PM"
    },
    fileInput: {
        counter: "Quantity"
    },
    close: "Close"
};
exports.default = _default;
