// sessionStorage/localStorage keys:
export const CONTRACT_ACTIVE_TAB = "ahio";
export const LOGGED_IN = "hmmm";
export const MESSAGES_ACTIVE_TAB = "msg";

//url login via SB
export const SB_URL = "https://e.sb.lt/authorization/login?system=SBD";

// backend response errors:
export const ERROR_CONN = "NO_CONNECTION";
export const ERROR_AUTH = "ERROR_CODE_AUTHENTICATION";
export const ERROR_SESS = "ERROR_CODE_USER_SESSION_ENDED";
export const ERROR_UNKNOWN = "ERR_CODE_UNKNOWN_EXCEPTION";
export const ERROR_EXCHANGE = "ERROR_CODE_EXCHANGE_IN_PROGRESS";
export const ERROR_AML = "ERROR_CODE_AML";

// Route names
export const ROUTE_LOGIN = "login";
export const ROUTE_ROLES = "roles";
export const ROUTE_USERS = "users";
export const ROUTE_USER_UPDATE = "user";
export const ROUTE_CONTRACTS = "contracts";
export const ROUTE_CONTRACT = "contract";
export const ROUTE_BENEFICIARY = "beneficiary";
export const ROUTE_CLAIM = "claimRegistration";
export const ROUTE_CLIENTS = "clients";
export const ROUTE_CLIENT = "client";
export const ROUTE_CLIENTINFO = "clientInfo";
export const ROUTE_FAQ = "faq";
export const ROUTE_MESSAGES = "messages";
export const ROUTE_MESSAGE = "message";
export const ROUTE_PAYMENTS = "payments";
export const ROUTE_PAYMENT = "payment";
export const ROUTE_REQUESTS = "requests";
export const ROUTE_SYSPARAMS = "sysparams";
export const ROUTE_TRANSLATIONS = "systrans";
export const ROUTE_REPORTS = "reports";
export const ROUTE_KYC = "kyc";
export const ROUTE_SENDINGWAYS = "sendingInfo";

export const NAV_OPTIONS = [
  ROUTE_CONTRACTS,
  ROUTE_PAYMENTS,
  ROUTE_REQUESTS,
  ROUTE_CLAIM,
  ROUTE_CLIENTS,
  ROUTE_SYSPARAMS,
  ROUTE_TRANSLATIONS,
  ROUTE_USERS,
  ROUTE_REPORTS,
  ROUTE_KYC
];

export const ROLE_CLIENT = "CLIENT";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_SADMIN = "SUPERADMIN";

export const LINK_FAQ = "https://sbdraudimas.lt/duk/#savitarna";

export const REGISTERED = "REGISTERED";
export const CONTRACT_VALID_STATES = ["VALID", "ALLOWED_NOT_TO_PAY", "STOPPED", "CONFIRMED", "REGISTERED", "RENEWED"];
export const CONTRACT_INVALID_STATES = ["TERMINATED", "EXPIRED", "ENDED"];
export const CONTRACT_STATE_KEYS = [...CONTRACT_VALID_STATES, ...CONTRACT_INVALID_STATES];
export function CONTRACT_STATES() {
  return textValArray(CONTRACT_STATE_KEYS, "const.contractState.");
}

export const DATE_GPM = "2003-01-01";
export const DATE_DIA = "2004-05-03";

// seems same for now, but keep this conversion logic in case UI will require different format at some point
export const DTO_DATE_FORMAT = "YYYY-MM-DD";
export const DTO_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const JS_DATE_FORMAT = "YYYY-MM-DD";
export const JS_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

export const JS_TIME_FORMAT_FULL = "HH:mm:ss";
export const JS_TIME_FORMAT = "HH:mm";

export const OTHER_DOCUMENTS = "other";
export const MEDICAL_DOCUMENTS = "medical";

export const FILES_TYPES = [OTHER_DOCUMENTS, MEDICAL_DOCUMENTS, "id"];

export const CLIENT_TYPES_KEYS = ["FA", "JA"];
export function CLIENT_TYPES() {
  return textValArray(CLIENT_TYPES_KEYS, "const.client.");
}

export const LANG_KEY = "lang";

export const LANGUAGES_KEYS = ["LT", "EN", "RU"];
export function LANGUAGES() {
  return textValArray(LANGUAGES_KEYS, "const.lang.");
}

export const LANGUAGE_LT_KEY = ["LT"];
export function LANGUAGE_LT() {
  return textValArray(LANGUAGE_LT_KEY, "const.lang.");
}

export const STATUS_DAMAGE_VALID = "VALID";
export const STATUS_DAMAGE_KEYS = ["CONFIRMED", STATUS_DAMAGE_VALID, "CANCELLED"];
export function STATUS_DAMAGES() {
  return textValArray(STATUS_DAMAGE_KEYS, "const.statusDamage.");
}
export const STATUS_DAMAGE_ASSIGNABLE_KEYS = ["CANCELLED", "CONFIRMED"];
export function STATUS_DAMAGES_ASSIGNABLE() {
  return textValArray(STATUS_DAMAGE_KEYS, "const.statusDamage.");
}

export function DAMAGE_TYPES() {
  // direct pull from i18n, but maybe for consistency should make as STATUS_DAMAGE...
  // definitely need to make as above if at some point any of separate keys is defined as const and used...
  return Object.entries(window.vue.$t("const.claimType")).map(([key, value]) => {
    return { text: value, value: key };
  });
}

export const SENDING_INFO_EMAIL = "EMAIL";
export const SENDING_INFO_KEYS = [SENDING_INFO_EMAIL, "MAIL", "WEB"];
export function SENDING_INFO() {
  return textValArray(SENDING_INFO_KEYS, "const.sendingInfo.");
}
export const DOC_TYPE_KEYS = ["PASSPORT", "IDENTITY_CARD", "RESIDENCE_PERMIT"];
export function DOC_TYPES() {
  return textValArray(DOC_TYPE_KEYS, "const.docType.");
}

export const INCIDENT_PLACES_KEYS = ["WORK", "HOME", "SPORT", "TRAFFIC", "OTHER"];
export function INCIDENT_PLACES() {
  return textValArray(INCIDENT_PLACES_KEYS, "const.incidentPlace.");
}

export const TRAUMA_TYPE_KEYS = ["CD_DEATH", "CD_TRAUMA", "CD_CI"];
export function TRAUMA_TYPES() {
  return textValArray(TRAUMA_TYPE_KEYS, "const.traumaType.");
}

export const DEATH = "CD_DEATH";
export const CRITICAL_ILL = "CD_CI";

export const STATUS_MESSAGE = ["SEEN", "UNSEEN"];
export function STATUS_MESSAGES() {
  return textValArray(STATUS_MESSAGE, "const.statusMessage.");
}

export const STATUS_MESSAGE_ADMIN = ["SEEN", "NEW", "ANSWERED"];
export function STATUS_MESSAGES_ADMIN() {
  return textValArray(STATUS_MESSAGE_ADMIN, "const.statusMessage.");
}

export const FORCE_ROLE_SELECT_KEY = "doRikeSekect";

export const REQUEST_TYPE_KEYS = ["FUND_CHANGE", "PAYOUT", "PARTIAL_CAPITAL"];
export function REQUEST_TYPES_INV() {
  return textValArray(REQUEST_TYPE_KEYS, "const.request.");
}

export const REQUEST_TYPE_KEYS_2 = ["PAYOUT", "PARTIAL_CAPITAL"];
export function REQUEST_TYPES_GD() {
  return textValArray(REQUEST_TYPE_KEYS_2, "const.request.");
}

export const REQUEST_TYPE_KEYS_3 = ["FREE_FORM"];
export function REQUEST_TYPES_FREE_FORM() {
  return textValArray(REQUEST_TYPE_KEYS_3, "const.request.");
}

export const FUND_CHANGE = "FUND_CHANGE";
export const PARTIAL_CAPITAL = "PARTIAL_CAPITAL";
export const PAYOUT = "PAYOUT";
export const FREE_FORM = "FREE_FORM";

export const CHANGED_REASON_KEYS = ["DEATH", "MARRIAGE", "DIVORCE", "CHILD", "OTHER"];
export function REASONS_TYPES() {
  return textValArray(CHANGED_REASON_KEYS, "const.changedReason.");
}

export const PREMIUM_TAKEN_KEYS = ["NO", "FULLY", "PARTIALLY", "NO_INFO"];
export function PREMIUM_TAKEN_TYPES() {
  return textValArray(PREMIUM_TAKEN_KEYS, "const.premiumTaken.");
}

export const JURIDICAL_PAID_KEYS = ["NO", "FULLY", "PARTIALLY", "NO_INFO"];
export function JURIDICAL_PAID_TYPES() {
  return textValArray(JURIDICAL_PAID_KEYS, "const.juridicalPaid.");
}

export const AGREEMENT_PRODUCT = "AGREEMENT_PRODUCT";
export const AGREEMENT_PERIOD = "AGREEMENT_PERIOD";
export const AGREEMENT_NO = "AGREEMENT_NO";
export const CLIENT_CODE = "CLIENT_CODE";
export const MESSAGE_CRITERION_KEYS = ["ALL", AGREEMENT_PRODUCT, AGREEMENT_PERIOD, AGREEMENT_NO, CLIENT_CODE];
export function MESSAGE_CRITERIA() {
  return textValArray(MESSAGE_CRITERION_KEYS, "const.message.criterion.");
}

export const MESSAGE_CONTRACT_TYPE_KEYS = ["BK", "GD", "GN", "GNN", "GP", "IP", "KG", "KI", "PD", "PP", "RG", "SB", "SD", "UG", "VA", "VP"];
export function MESSAGE_CONTRACT_TYPES() {
  return textValArray(MESSAGE_CONTRACT_TYPE_KEYS, "const.message.contractType.");
}

export const MESSAGE_TOPIC_KEYS = ["QUESTION", "SERVICE", "REVIEW", "OTHER"];
export function MESSAGE_TOPICS() {
  return textValArray(MESSAGE_TOPIC_KEYS, "const.message.topic.");
}

export const MESSAGE_ANSWER_WAY_KEYS = ["PHONE", "EMAIL", "WEB"];
export function MESSAGE_ANSWER_WAYS() {
  return textValArray(MESSAGE_ANSWER_WAY_KEYS, "const.message.answer.");
}

export const PAYMENT_TYPE_KEYS = ["PERIODIC", "INVESTMENT"];
export function PAYMENT_TYPES() {
  return textValArray(PAYMENT_TYPE_KEYS, "const.paymentType.");
}
//-----------------------------------
function textValArray(keys, i18nRoot) {
  if (window.vue)
    return keys.map(key => {
      return { text: window.vue.$t(i18nRoot + key), value: key };
    });
  else console.error("Vue needs to be registered in window (window.vue). Call when Vue is loaded!");
}
